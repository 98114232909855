html, body {
	height: 100%;
	margin: 0 !important;
	padding: 0 !important;
	font-family: 'Roboto', sans-serif;
	color: #333; /* Dark gray for readability */
	background-color: #f9f9f9; /* Light background for contrast */
	line-height: 1.6; /* Improved readability */
	margin: 0;
	padding: 0;
}

/* Link styles */
a {
	color: #0066cc; /* Bright blue for visibility */
	text-decoration: none; /* Remove underline for a clean look */
	font-weight: 500; /* Slightly bold for emphasis */
	transition: color 0.3s ease-in-out; /* Smooth hover effect */
}

a:hover, a:focus {
	color: #004999; /* Darker blue for hover */
	text-decoration: underline; /* Highlight on hover */
}

/* Button links */
button, .button {	
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	font-size: 1rem;
	cursor: pointer;
	transition: background-color 0.3s ease-in-out;
}

/* Hover effect to make the button lighter */
button:hover, .button:hover {
    filter: brightness(1.2); /* Increase brightness by 20% */
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
	color: #222; /* Slightly darker for headings */
	margin-bottom: 15px;
}